export function ensureHostname(url: string) {
  url = url?.trim() || '';

  if (!url) {
    return '';
  }

  if (url.startsWith('http')) {
    return url;
  }

  return location.origin + url;
}
