export function waitForCondition(fn: () => boolean, interval: number, timeout: number): Promise<boolean> {
  return new Promise((resolve) => {
    const startTime = Date.now();
    const intervalId = setInterval(function () {
      if (Date.now() - startTime > timeout) {
        clearInterval(intervalId);
        resolve(false);
      }

      if (fn()) {
        clearInterval(intervalId);
        resolve(true);
      }
    }, interval);
  });
}
