import { validUrl } from '../../utils';
import { EmbedLauncher } from '../shared/launcher';
import { ViewedPage } from './viewed-page';
import { ViewedProduct, ViewedProductPatch } from './viewed-product';

export async function initiate() {
  window.OMNISEND_WOOCOMMERCE_LOADED = true;

  if (!validUrl((document.currentScript as HTMLScriptElement).src)) {
    // eslint-disable-next-line no-console
    console.log('Invalid script URL');
    return;
  }

  const brandID =
    new URL((document.currentScript as HTMLScriptElement).src).searchParams.get('brandID') || window.omnisend_snippet_vars?.brand_id;
  const fromInshop = new URL((document.currentScript as HTMLScriptElement).src).searchParams.get('fromInshop');

  await EmbedLauncher(brandID, 'api');

  ViewedPage(!!fromInshop);
  ViewedProductPatch();
  ViewedProduct();
}
