import { waitForCondition } from '../../../utils';
import { getProduct, getProductVariants } from './api';
import { mapProduct } from './product-mapper';
import { WooProduct } from './product-types';
import { mapWooProductToVariant } from './variant-mapper';

export async function ViewedProduct() {
  const isProductPage = await waitForCondition(() => !!window.wc_single_product_params, 1000, 5000);

  if (!isProductPage) {
    return;
  }

  setInterval(() => {
    trackViewedProductEvent();
  }, 1000);
}

let lastVariantId: string;

export async function trackViewedProductEvent() {
  const variantId = getVariantId();

  if (lastVariantId === variantId) {
    return;
  }

  lastVariantId = variantId;

  const productIds = getProductIds();

  if (!productIds.length) {
    return;
  }

  let wooProduct: WooProduct;

  try {
    wooProduct = await getProduct(productIds[0]);
  } catch {
    wooProduct = await getProduct(productIds[1]);
  }

  if (!wooProduct || !wooProduct.id) {
    return;
  }

  const wooVariant = await getVariant(wooProduct.id, variantId);

  const product = mapProduct(wooProduct, wooVariant);

  window.omnisend.push([
    'track',
    'viewed product',
    {
      properties: {
        product: product
      }
    }
  ]);
}

function getVariantId() {
  const elem = document.getElementsByName('variation_id')[0];

  if (!elem) {
    return '';
  }

  const variantId = elem.getAttribute('value');

  if (variantId === '0') {
    return '';
  }

  return variantId || '';
}

function getProductIds() {
  const splitPathname = location.pathname.split('/').filter((x) => x?.trim());
  const productSlug = (splitPathname || []).pop();
  const productId = window.omnisend_product?.productID;

  return [productId, productSlug].filter((x) => !!x);
}

async function getVariant(productId: number, variantId: string) {
  const wooVariants = await getProductVariants(productId);

  let variant = wooVariants?.find((v) => String(v.id) === variantId);

  if (!variant && variantId && variantId !== '') {
    const productByVariantId = await getProduct(variantId);

    variant = mapWooProductToVariant(productByVariantId);
  }

  return variant;
}

// exported for unit tests only
export function resetLastVariantId() {
  lastVariantId = undefined;
}
