export async function request<T, B>(
  url: string,
  method: 'get' | 'post' | 'delete' = 'get',
  body: B | undefined = undefined,
  headers = {}
): Promise<T> {
  try {
    const res = await fetch(url, {
      method: method.toUpperCase(),
      body: typeof body === 'object' ? JSON.stringify(body) : undefined,
      headers: {
        'Content-type': 'application/json',
        ...headers
      }
    });

    if (!res.ok) {
      // whole response is rejected for further handling, in example reponse status 404 retries
      return Promise.reject(res);
    }

    // prevents uncaught promise errors on successful requests
    try {
      return await res.json();
    } catch {
      Promise.resolve();
    }
  } catch (error) {
    return Promise.reject(error);
  }
}
