import { OmnisendPublic } from '../../../context/window';
import { waitForCondition } from '../../../utils/wait-for';
import { AddScript } from './add-script';

export function EmbedLauncher(brandID: string, shopType: string) {
  if (window.OMNISEND_LAUNCHER_LOADED) {
    return Promise.resolve(true);
  }

  setupInshopLauncherContext(brandID, shopType);

  const dateVersion = new Date().toISOString().slice(0, 13);

  AddScript(`inshop/launcher-v2.js?v=${dateVersion}`);

  const isLauncherLoaded = () => window.OMNISEND_LAUNCHER_LOADED;

  return waitForCondition(isLauncherLoaded, 100, 3000);
}

function setupInshopLauncherContext(brandID: string, shopType: string) {
  window.omnisend = window.omnisend || ([] as unknown as OmnisendPublic);

  if (brandID) {
    window.omnisend.push(['accountID', brandID]);
  }

  // needed for page view event and push notifications
  window._omnisend = { ...window._omnisend, shopType };
}
