import { request } from '../../api';
import { WooProduct, WooVariant } from './product-types';

let cachedProduct: WooProduct = null;
let cachedProductVariants: WooVariant[] = null;

export async function getProduct(id: string) {
  if (cachedProduct && cachedProduct.id === Number(id)) {
    return Promise.resolve(cachedProduct);
  }

  try {
    const resp = await request<WooProduct, undefined>('/wp-json/wc/store/v1/products/' + id);

    cachedProduct = resp;

    return cachedProduct;
  } catch (error) {
    if ((error as Response).status === 404) {
      return Promise.reject();
    }

    return null;
  }
}

export async function getProductVariants(id: number) {
  if (cachedProductVariants) {
    return Promise.resolve(cachedProductVariants);
  }

  try {
    const resp = await request<WooVariant[], undefined>(`/wp-json/wc/store/v1/products?type=variation&parent=${id}`);

    cachedProductVariants = resp;

    return cachedProductVariants;
  } catch {
    return null;
  }
}
