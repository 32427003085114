// prevent $productViewed (legacy) event tracking from Omnisend Woo plugin
export function ViewedProductPatch() {
  if (!window.omnisend?.push) {
    return;
  }

  const push = window.omnisend.push;

  window.omnisend.push = (event: string[]) => {
    if (event?.[0] === 'track' && event?.[1] === '$productViewed') {
      return;
    }

    push(event);
  };
}
