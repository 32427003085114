import { WooProduct, WooVariant } from './product-types';

export function mapWooProductToVariant(product: WooProduct) {
  if (!product || !product.id) {
    return;
  }

  return {
    id: product.id,
    name: product.name,
    short_description: product.short_description,
    is_in_stock: product.is_in_stock,
    prices: product.prices,
    images: product.images,
    permalink: product.permalink
  } as WooVariant;
}
