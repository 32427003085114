import { SNIPPET_HOST } from '../../../context/environment';

export function AddScript(scriptURL: string) {
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;
  script.src = `${SNIPPET_HOST}${scriptURL}`;

  const firstScript = document.getElementsByTagName('script')[0];

  if (firstScript) {
    firstScript.parentNode.insertBefore(script, firstScript);
    return;
  }

  document.head.appendChild(script);
}
