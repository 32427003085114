import { ensureHostname } from '../../../utils';
import { OmnisendWooProduct, WooPrices, WooProduct, WooVariant } from './product-types';

export function mapProduct(product: WooProduct, variant: WooVariant) {
  if (!product) {
    return;
  }

  variant = variant || ({} as WooVariant);

  const prices = variant.prices || product.prices || ({} as WooPrices);
  const images = variant.images || product.images || [];
  const permalink = variant.permalink || product.permalink;
  const isInStock = 'is_in_stock' in variant ? variant.is_in_stock : product.is_in_stock;

  const currencyMinorUnit = prices.currency_minor_unit;

  let price = parseInt(prices.price);
  price = convertPriceTo100ths(price, currencyMinorUnit);

  let oldPrice = prices.price !== prices.regular_price ? parseInt(prices.regular_price) : null;
  oldPrice = oldPrice && convertPriceTo100ths(oldPrice, currencyMinorUnit);

  return {
    id: String(product.id),
    currency: prices.currency_code,
    price: price,
    oldPrice: oldPrice,
    title: product.name,
    description: product.short_description,
    imageUrl: ensureHostname(images[0]?.src || ''),
    url: permalink,
    status: isInStock ? 'inStock' : 'outOfStock',
    categories: product.categories.map((category) => ({ id: String(category.id), title: category.name })),
    variantId: variant.id ? String(variant.id) : ''
  } as OmnisendWooProduct;
}

export function convertPriceTo100ths(price: number, currencyMinorUnit: number) {
  // WooCommerce Store API returns a "currency_minor_unit", which is the shop setting for how many decimal places to show in the product price.
  // currency_minor_unit == 2 means the price is in 1/10^2, or 1/100ths (cents). A price of 25$ would be returned by API as 2500.
  // currency_minor_unit == 1 means the price is in 1/10^1, or 1/10ths. A price of 25$ would be returned by API as 250.
  // currency_minor_unit == 0 means the price is in 1/10^0, or 1s. A price of 25$ would be returned by API as 25.
  // We need to to send the price as 1/100ths (cents) to Data Platform, so we need to convert the price to currency_minor_unit == 2 if it is different.
  const targetCurrencyMinorUnit = 2;

  if (Number.isInteger(currencyMinorUnit) && currencyMinorUnit !== targetCurrencyMinorUnit) {
    const factor = Math.pow(10, targetCurrencyMinorUnit - currencyMinorUnit);

    return (price *= factor);
  }

  return price;
}
